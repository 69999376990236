import { Modify } from "@biblioteksentralen/types";
import { Recommendation } from "@libry-content/types";
import groq from "groq";
import { resolveCreatedBySummary, ResolvedCreatedBySummary } from "../../employees";
import { PublicationSummary, resolvePublicationSummary } from "./resolvePublication";

const resolvePalette = groq`
  "palette": publication.image.asset -> metadata.palette {
    "background": lightMuted.background,
    "createdBy": muted.background
  }
`;

export type RecommendationPalette = {
  background: string;
  createdBy: string;
};

export const resolveRecommendationGroqProjection = groq`
  ...,
  createdBy -> ${resolveCreatedBySummary},
  ${resolvePalette}
`;

export type ResolvedRecommendation = Modify<
  Recommendation,
  { createdBy?: ResolvedCreatedBySummary; palette?: RecommendationPalette }
>;

export const resolveRecommendationSummary = groq`
  _id,
  _type,
  _createdAt,
  teaser,
  targetAudiences,
  promotion,
  publication { ${resolvePublicationSummary} },
  createdBy -> ${resolveCreatedBySummary},
  ${resolvePalette}
`;

export type ResolvedRecommendationSummary = Pick<
  Recommendation,
  "_type" | "_id" | "_createdAt" | "teaser" | "targetAudiences" | "promotion"
> & {
  publication?: PublicationSummary;
  createdBy?: ResolvedCreatedBySummary;
  palette?: RecommendationPalette;
};
