import { Modify } from "@biblioteksentralen/types";
import { Event, RepeatedEventCustomization } from "@libry-content/types";
import groq from "groq";
import { isEqual } from "radash";
import { ResolvedEvent, ResolvedEventSummary } from "./resolveEvent";

const resolveRepeatedDateSummary = groq`
  "_type": "repeatedDateSummary",
  "_id": _key,
  "startDate": date,
  "eventStart": coalesce(customization.eventStart, ^.eventStart),
  "eventEnd": coalesce(customization.eventEnd, ^.eventEnd),
  "eventStatus": coalesce(customization.eventStatus, ^.eventStatus),
  "title": coalesce(customization.title, ^.title),
`;

export type ResolvedRepeatedDateSummary = Pick<
  RepeatedEventCustomization,
  "eventStart" | "eventEnd" | "eventStatus"
> & {
  startDate: NonNullable<Event["repeatedDates"]>[number]["date"];
  _id: string;
  _type: "repeatedDateSummary";
  title?: Event["title"];
};

const resolveRepeatedDate = groq`
  ...^, // Spread the parent event
  "promotion": null, // Erase parent promotion
  "repeatedDates": null, // Erase repeated dates array to avoid confusion
  "repeated": null, // Erase repeated flag to avoid confusion
  ...customization, // Spread the single date customization
  "startDate": date, // Single date
  "_id": _key, // Single date key as id
  "series": {
    "id": ^._id, // Series id from the parent event
    "title": ^.title, // Series title from the parent event
    "repeatedDates": ^.repeatedDates[] {${resolveRepeatedDateSummary}}
  },
  "_type": "resolvedRepeatedEventOccurence" // Use unique type to distinguish from event-document
`;

export type ResolvedRepeatedEventOccurence = Modify<
  Omit<Event, "repeatedDates" | "repeated">,
  {
    series: {
      id: string;
      repeatedDates: ResolvedRepeatedDateSummary[];
      title: Event["title"]; // if the occurence does not have a custom title "series.title" will be the same as "title"
    };
    _type: "resolvedRepeatedEventOccurence";
  }
>;

// By performing the flattening in the query, we can use groq's ordering and pagination.
export const resolveRepeatedEvents = (repeatedDateFilter = "") => groq`
  {"occurrences": select(
    repeated => repeatedDates[${repeatedDateFilter}] {${resolveRepeatedDate}},
    [@] // Non-repeated event
  )}
  .occurrences[] // Flatten
 `;

export const isRepeatedEventOccurence = <T extends ResolvedRepeatedEventOccurence>(doc: unknown): doc is T =>
  (doc as ResolvedRepeatedEventOccurence)?._type === "resolvedRepeatedEventOccurence";

export const isEvent = <T extends Event>(doc: unknown): doc is T => (doc as Event)?._type === "event";

export const getEventHeadings = (event: ResolvedEvent | ResolvedEventSummary) => {
  const heading = event.title;
  const subheading = isRepeatedEventOccurence(event) ? event.series.title : undefined;

  return {
    heading,
    subheading: isEqual(heading, subheading) // If the heading and subheading are the same, we don't want to show the subheading
      ? undefined
      : subheading,
  };
};
