import { Modify } from "@biblioteksentralen/types";
import { Service, ServiceType } from "@libry-content/types";
import groq from "groq";

export const resolveServiceGroqProjecton = groq`
  ...,
  serviceType->
`;

export type ResolvedService = Modify<Service, { serviceType?: ServiceType }>;

export const resolveServiceSummary = groq`
  _type,
  _id,
  title,
  image,
  targetAudiences,
  teaser,
  promotion,
  serviceType->{
    _id,
    label
  }
`;

export type ResolvedServiceSummary = Pick<
  Service,
  "_id" | "title" | "_type" | "image" | "targetAudiences" | "teaser" | "promotion"
> & {
  serviceType?: Pick<ServiceType, "_id" | "label">;
};
