import { InstanceSettings, Site } from "@libry-content/types";
import groq from "groq";
import { filters } from "../filters";

const resolveInstanceCountry = groq`"instanceCountry": GLOBAL[_type == "instanceSettings" && ${filters.isNotDraft}][0].country`;

export const resolveSiteQuery = groq`
  ...,
  ${resolveInstanceCountry}
`;

export type ResolvedSite = Site & {
  instanceCountry?: InstanceSettings["country"];
};

export const resolveSiteSummary = groq`
  _id,
  _type,
  name,
  defaultLanguage,
  domain,
  municipality,
  ${resolveInstanceCountry}
`;

export type ResolvedSiteSummary = Pick<
  Site,
  "_id" | "_type" | "name" | "defaultLanguage" | "domain" | "municipality"
> & {
  instanceCountry?: InstanceSettings["country"];
};
